var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "ProductDetailPop:dele",
                      expression: "'ProductDetailPop:dele'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "ProductDetailPop:add",
                  expression: "'ProductDetailPop:add'",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.openAddForm },
            },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "has",
              rawName: "v-has",
              value: "statistics:settlement:search",
              expression: "'statistics:settlement:search'",
            },
          ],
          staticClass: "table-page-search-wrapper",
          attrs: { slot: "search" },
          slot: "search",
        },
        [
          _c(
            "a-form",
            {
              attrs: { layout: "inline" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入作品名" },
                        model: {
                          value: _vm.queryParam.onlineName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParam, "onlineName", $$v)
                          },
                          expression: "queryParam.onlineName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.searchQuery },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        ref: "table",
        attrs: {
          id: "out-table",
          size: "middle",
          rowKey: (record) => record.productionOnline.id,
          columns: _vm.columns,
          customRow: _vm.clickRow,
          dataSource: _vm.dataSource,
          pagination: _vm.ipagination,
          loading: _vm.loading,
          scroll: { x: "max-content", y: "calc(100vh - 353px)" },
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 45,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "sort",
            fn: function (text, record, index) {
              return _c("span", {}, [
                _vm._v("\n      " + _vm._s(index + 1) + "\n    "),
              ])
            },
          },
          {
            key: "cover",
            fn: function (text, record) {
              return _c("span", {}, [
                _c("img", {
                  staticStyle: { width: "90px", height: "120px" },
                  attrs: {
                    src:
                      record.productionOnline && record.productionOnline.cover,
                    alt: "",
                  },
                }),
              ])
            },
          },
          {
            key: "onlineName",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    record.productionOnline &&
                      record.productionOnline.onlineName
                  )
                ),
              ])
            },
          },
          {
            key: "onlineTime",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    record.productionOnline &&
                      record.productionOnline.onlineTime &&
                      record.productionOnline.onlineTime.split(" ")[0]
                  )
                ),
              ])
            },
          },
          {
            key: "platform",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    record.productionOnline && record.productionOnline.platform
                  )
                ),
              ])
            },
          },
          {
            key: "businessTypes",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                _vm._l(record.businessTypes, function (item) {
                  return _c(
                    "div",
                    { key: item.id },
                    [
                      _c("a-tag", [
                        _vm._v(
                          "\n          " + _vm._s(item.value) + "\n        "
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                0
              )
            },
          },
          {
            key: "link",
            fn: function (text, record) {
              return _c("span", {}, [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        record.productionOnline && record.productionOnline.link,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        record.productionOnline && record.productionOnline.link
                      )
                    ),
                  ]
                ),
              ])
            },
          },
          {
            key: "publisher",
            fn: function (text, record) {
              return _c("span", {}, [
                record.publisher && record.publisher.avatar
                  ? _c("img", {
                      staticStyle: {
                        width: "30px",
                        height: "30px",
                        "border-radius": "50%",
                      },
                      attrs: { src: record.publisher.avatar, alt: "" },
                    })
                  : _vm._e(),
                _c("span", { staticStyle: { "margin-left": "3px" } }, [
                  _vm._v(_vm._s(record.publisher && record.publisher.realname)),
                ]),
              ])
            },
          },
          {
            key: "gmtModified",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    record.productionOnline &&
                      record.productionOnline.gmtModified
                  )
                ),
              ])
            },
          },
          {
            key: "showHome",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm.getShowHome(
                      record.productionOnline &&
                        record.productionOnline.publishTime
                    )
                  )
                ),
              ])
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        disabled:
                          record.productionOnline &&
                          !record.productionOnline.publishable,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.send(record)
                        },
                      },
                    },
                    [_vm._v("发布")]
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        disabled:
                          record.productionOnline &&
                          !record.productionOnline.cancelable,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.stop(record)
                        },
                      },
                    },
                    [_vm._v("停用")]
                  ),
                ]
              )
            },
          },
        ]),
      }),
      _c("product-pop-modal", {
        attrs: {
          userInfo: _vm.userInfo,
          open: _vm.drawerShow,
          "cur-row": _vm.curRow,
          isForm: _vm.isNewForm,
          dictList: _vm.dictList,
        },
        on: { closed: _vm.drawerClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }