<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'ProductDetailPop:dele'"
        >删除</a-button
      >
      <a-button v-has="'ProductDetailPop:add'" type="primary" @click="openAddForm">新建</a-button>
    </div>
    <div class="table-page-search-wrapper" v-has="'statistics:settlement:search'" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input v-model="queryParam.onlineName" placeholder="请输入作品名" />
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table
      ref="table"
      id="out-table"
      size="middle"
      :rowKey="record => record.productionOnline.id"
      :columns="columns"
      :customRow="clickRow"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 353px)' }"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 45 }"
      @change="handleTableChange"
    >
      <span slot="sort" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="cover" slot-scope="text, record">
        <img :src="record.productionOnline && record.productionOnline.cover" alt="" style="width:90px;height:120px;" />
      </span>
      <span slot="onlineName" slot-scope="text, record">{{
        record.productionOnline && record.productionOnline.onlineName
      }}</span>
      <span slot="onlineTime" slot-scope="text, record">{{
        record.productionOnline &&
          record.productionOnline.onlineTime &&
          record.productionOnline.onlineTime.split(' ')[0]
      }}</span>
      <span slot="platform" slot-scope="text, record">{{
        record.productionOnline && record.productionOnline.platform
      }}</span>
      <span slot="businessTypes" slot-scope="text, record">
        <div v-for="item in record.businessTypes" :key="item.id">
          <a-tag>
            {{ item.value }}
          </a-tag>
        </div>
      </span>
      <span slot="link" slot-scope="text, record">
        <a @click.stop :href="record.productionOnline && record.productionOnline.link">{{
          record.productionOnline && record.productionOnline.link
        }}</a>
      </span>
      <span slot="publisher" slot-scope="text, record">
        <img
          v-if="record.publisher && record.publisher.avatar"
          :src="record.publisher.avatar"
          alt=""
          style="width:30px;height:30px;border-radius: 50%;"
        />
        <span style="margin-left:3px">{{ record.publisher && record.publisher.realname }}</span>
      </span>
      <span slot="gmtModified" slot-scope="text, record">{{
        record.productionOnline && record.productionOnline.gmtModified
      }}</span>
      <span slot="showHome" slot-scope="text, record">{{
        getShowHome(record.productionOnline && record.productionOnline.publishTime)
      }}</span>
      <span @click.stop slot="action" slot-scope="text, record">
        <a :disabled="record.productionOnline && !record.productionOnline.publishable" @click="send(record)">发布</a>
        <a
          :disabled="record.productionOnline && !record.productionOnline.cancelable"
          @click="stop(record)"
          style="margin-left:15px"
          >停用</a
        >
        <!--  <a @click="editClick(record)" style="margin-left:15px">编辑</a> -->
      </span>
    </a-table>
    <product-pop-modal
      :userInfo="userInfo"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      :dictList='dictList'
      @closed="drawerClosed"
    ></product-pop-modal>
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import productPopModal from './modules/product/product-pop-modal.vue'
import { getAction, putAction, postAction, deleteAction } from '@/api/manage'
import { handleArrayParams } from '@/utils/util'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
export default {
  mixins: [JeecgListMixin],
  components: {
    productPopModal,
  },
  data() {
    return {
      positionList: [],
      btnLoading: false,
      tablePage: {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0
      },
      translateUsers: [],
      userIds: [],
      monthFormat: 'YYYY-MM',
      month: '',
      isEdit: false,
      isAdmin: false,
      searchUsers: [],
      dictList:[],
      userInfo: {
        roles: []
      },
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: '/productionOnline/page',
        copyBatch: 'productDetail/copy',
        delete: '/fonts/delete',
        deleteBatch: '/productionOnline',
        syncPath: 'productDetail/sync'
      },
      color: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' },
          width: 50
        },
        {
          title: '封面',
          dataIndex: 'cover',
          scopedSlots: { customRender: 'cover' },
          width: 120
        },
        {
          title: '上线作品名称',
          dataIndex: 'onlineName',
          scopedSlots: { customRender: 'onlineName' },
          width: 180
        },
        {
          title: '上线时间',
          dataIndex: 'onlineTime',
          scopedSlots: { customRender: 'onlineTime' },
          width: 180
        },
        {
          title: '上线平台',
          dataIndex: 'platform',
          scopedSlots: { customRender: 'platform' },
          width: 150
        },
        {
          title: '业务',
          dataIndex: 'businessTypes',
          scopedSlots: { customRender: 'businessTypes' },
          width: 120
        },
        {
          title: '外链地址',
          dataIndex: 'link',
          scopedSlots: { customRender: 'link' },
          width: 150
        },
        {
          title: '发布人',
          dataIndex: 'publisher',
          scopedSlots: { customRender: 'publisher' },
          width: 150
        },
        {
          title: '修改时间',
          dataIndex: 'gmtModified',
          scopedSlots: { customRender: 'gmtModified' },
          width: 150
        },
        {
          title: '状态',
          dataIndex: 'showHome',
          scopedSlots: { customRender: 'showHome' },
          width: 150
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 120
        }
      ]
    }
  },
  methods: {
    async getDictList(){
      const res = await getAction('/sys/dict/list',{pageNo: 1, pageSize: 50,dictCode:'sxzpywlx'})
      if(res.code==200){
        const restItem = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: res.data.records[0].id })
        this.dictList = restItem.data.records
        console.log(this.dictList,789)
      }
    },
    getShowHome(time) {
      let text = '未展示'
      if (time) {
        //发布时间是否超过当前时间
        if (new Date(time).getTime() < new Date().getTime()) {
          text = '首页展示中'
        }
      }
      return text
    },
    batchDel: function() {
      let data = []
      let that = this
      if (!this.selectionRows.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.selectionRows?.forEach(item => {
        data.push(JSON.parse(JSON.stringify(item)).productionOnline.id)
      })
      data = data.join(',')
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          deleteAction('/productionOnline?ids=' + data)
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.loadData()
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    },
    userSearch(value) {
      if (value) {
        this.searchUsers = this.translateUsers.filter(item => item.userName.indexOf(value) > -1)
      } else {
        this.searchUsers = []
      }
    },
    searchQueryForm() {
      if (this.month && this.month.format) {
        this.queryParam.month = this.month.format(this.monthFormat)
      } else {
        this.queryParam.month = ''
      }
      this.queryParam.userIds = this.userIds
    },
    async stop(record) {
      const res = await putAction('productionOnline/unpublish/' + record.productionOnline.id)
      if (res.code == 200) {
        this.$message.success('已停用')
        this.loadData()
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    async send(record) {
      const res = await putAction('productionOnline/publish', { id: record.productionOnline.id })
      if (res.code == 200) {
        this.$message.success('发布成功')
        this.loadData()
      } else {
        this.$message.error(res.msg || res.message)
      }
    }
  },
  created() {
    this.getDictList()
    /*     this.userInfo = Vue.ls.get(USER_INFO)
    this.isAdmin = false
    if (this.userInfo.roles) {
      this.userInfo.roles.forEach(one => {
        if (one.roleCode == 'admin' || one.roleCode == 'boss' || one.roleCode == 'tongchou' || one.roleCode == 'hr') {
          this.isAdmin = true
        }
      })
    } */
  }
}
</script>

<style lang="less" scoped>
.red {
  color: red;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
</style>
